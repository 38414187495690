<template>
  <div>
    <PhasesForm
      v-model="phases"
      :generate-id-when-create="generateIdWhenCreate"
    ></PhasesForm>
  </div>
</template>

<script>
import PhasesForm from '@/components/areas/registries/services/phases/PhasesForm.vue'
import serviceForm from '@/services/services/service.form.js'

export default {
  name: "PhaseFormTab",
  components: {
    PhasesForm
  },
  data: function() {
    return {
      phases: [ ],
    }
  },
  props: {
    generateIdWhenCreate: {
      type: Boolean,
      default: false
    }
  },
  mounted: function() {
    this.handleObjectChanges(serviceForm.service)

    var self = this
    serviceForm.on('update', function(data) {
      self.handleObjectChanges(data.service)
    })
  },
  methods: {
    handleObjectChanges(service) {
      if(!this.deepObjectArrayEqual(service.servicePhases, this.phases)) {
        this.phases = service.servicePhases
      }
    },
    deepObjectArrayEqual(array1, array2) {
      if((!array1 && !!array2) || (!!array1 && !array2)) {
        return false
      } 
      if(!array1 && !array2) {
        return true
      }
      
      if(array1.length != array2.length) {
        return false
      }

      for(let i = 0; i < array1.length; i++) {
        for (const [key, value] of Object.entries(array1[i])) {
          if(value !== array2[i][key]) {
            return false
          }
        }
      }
      return true
    }
  },
  computed: {
  },
  watch: {
    phases(newVal) {
      serviceForm.updateField('servicePhases', newVal)
    }
  }
}
</script>

<style>

.draggable-area {
	cursor: move
}

.ghost {
	background-color: #b6b6b6;
	opacity: 0.2;
}

</style>